import axiosClient from '../utils/axiosClient';
import AppResult, { withSafety } from '../utils/result';

interface SendLoginParams {
  email: string;
  password: string;
}

class AuthService {
  async login({
    email,
    password,
  }: SendLoginParams): Promise<{ data: any; error: any }> {
    return withSafety(async () => {
      const res = await axiosClient.post('/admin/login', {
        email,
        password,
      });

      return AppResult.withData(res?.data);
    });
  }

  async logout(): Promise<{ data: any; error: any }> {
    return withSafety(async () => {
      await fetch('/api/auth/logout');

      return AppResult.withData({ message: 'Logout successful' });
    });
  }

  async getUser(): Promise<{ data: any; error: any }> {
    return withSafety(async () => {
      const res = await axiosClient.get('/admin/me');

      return AppResult.withData(res?.data);
    });
  }
}

export default AuthService;
