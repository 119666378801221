import { lazy } from 'react';
import { Features } from '../utils/common';

export const routes = [
  {
    name: 'login',
    path: '/login',
    component: lazy(() => import('../pages/Login')),
    isProtected: false,
  },
  {
    name: 'app',
    path: '/dashboard',
    component: lazy(() => import('../pages/Layout')),
    isLayout: true,
    isProtected: true,
    routes: [
      {
        page: Features.LEADS,
        path: Features.LEADS,
        component: lazy(() => import('../pages/Leads')),
        isProtected: true,
      },
      {
        page: Features.ORGANIZATION,
        path: Features.ORGANIZATION,
        component: lazy(() => import('../pages/OrganizationList')),
        isProtected: true,
      },
      {
        page: Features.INDIVIDUAL,
        path: Features.INDIVIDUAL,
        component: lazy(() => import('../pages/IndividualList')),
        isProtected: true,
      },
    ],
  },
];
