import React, { Suspense } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute, { AuthRedirect } from './hoc/ProtectedRoute';
import LoginPage from './pages/Login';
import { routes } from './routes/routes';
import { Features } from './utils/common';

const Login: React.FC = () => {
  return <LoginPage />;
};

const Home: React.FC = () => <h1>Home Page</h1>;

const App: React.FC = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <AuthProvider>
      <Router>
        <Routes>
          {routes.map((item, index) => {
            const Component = item?.component;
            const isProtected = item?.isProtected;
            const isLayout = item?.isLayout;
            const routes = item?.routes || [];
            const path = item?.path;

            if (isLayout) {
              return (
                <Route
                  key={index}
                  path={path}
                  element={
                    <ProtectedRoute>
                      <Component />
                    </ProtectedRoute>
                  }
                >
                  {routes?.length > 0 &&
                    routes?.map((internalRoute, i) => {
                      const Component = internalRoute?.component;
                      return (
                        <Route
                          key={i}
                          path={internalRoute?.path}
                          element={
                            <ProtectedRoute>
                              <Component />
                            </ProtectedRoute>
                          }
                        />
                      );
                    })}
                  <Route
                    path="/dashboard"
                    element={
                      <Navigate
                        to={`/dashboard/${Features.ORGANIZATION}`}
                        replace
                      />
                    }
                  />
                </Route>
              );
            } else
              return (
                <Route
                  key={index}
                  path={path}
                  element={
                    isProtected ? (
                      <ProtectedRoute>
                        <Component />
                      </ProtectedRoute>
                    ) : (
                      <AuthRedirect>
                        <Component />
                      </AuthRedirect>
                    )
                  }
                />
              );
          })}
          <Route path="*" element={<Navigate to="/dashboard/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  </Suspense>
);

export default App;
