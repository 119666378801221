class AppResult {
  error = null;
  data = null;

  constructor(data: any, error?: any) {
    this.data = data;
    this.error = error;
  }

  hasError() {
    return this.error != null;
  }

  static withError(error: any) {
    return new AppResult(null, error);
  }

  static withData(data: any) {
    return new AppResult(data, null);
  }
}

export const withSafety = async (block: any) => {
  try {
    return await block();
  } catch (error) {
    return AppResult.withError(error);
  }
};

export default AppResult;
