import axiosClient from '../utils/axiosClient';
import AppResult, { withSafety } from '../utils/result';

class LiquidateOrganizationService {
  async getOrganizationRequestList(): Promise<{ data: any; error: any }> {
    return withSafety(async () => {
      const res = await axiosClient.get('/liquidate/request-by-organization');

      return AppResult.withData(res?.data);
    });
  }
}

export default LiquidateOrganizationService;
